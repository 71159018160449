import React from 'react'
import { graphql } from 'gatsby'
import ArticlePage from '../components/templates/article'

const Publications = props => {
  const post = props.data.post.edges[0].node
  const publications = props.data.publications.edges
  
  const parentChildren = []
  publications.map(({ node }) => parentChildren.push(node))

  const keywords = []
  publications.map(( {node} ) => (
    node.frontmatter.keywords.map(keyword => {
      if (keywords.indexOf(keyword) === -1) {
        keywords.push(keyword)
      }
  })))

  const postData = {
    title: post.frontmatter.title,
    parentChildren: parentChildren,
    keywords: keywords,
    comments: false
  }

  return (
    <ArticlePage post={postData}/>
  )
}

export default Publications

export const listQuery = graphql`
query publicationQuery {
  publications: allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}, filter: {parent: {}, frontmatter: {parent: {eq: "/publications/"}}}) {
    edges {
      node {
        fields {
          slug
        }
        html
        frontmatter {
          title
          url
          tags
          keywords
        }
      }
    }
  }
  post: allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}, filter: {fields: {slug: {eq: "/publications/"}}}) {
    edges {
      node {
        frontmatter {
          title
        }
      }
    }
  }
}
`
